<template>
  <div class="p-0">
    <div class="bg-white p-8 rounded-lg shadow-xl py-8 mt-12">
      <h3 class="text-lg font-semibold">Documentação - Inteligência Artificial</h3>
      <div class="pb-12 mt-4">

        <!-- Layout Responsivo -->
        <div class="flex min-h-screen flex-row py-8">
          <!-- Sidebar -->
          <Menu />
          <!-- Main Content -->
          <main class="flex-1 px-6 bg-gray-100">
            <h1 class="text-2xl font-bold mb-4">Limitações do ChatGPT</h1>
  <p class="mb-4">
    O ChatGPT possui limitações que precisam ser compreendidas para evitar frustrações no uso do sistema:
  </p>

  <h2 class="text-xl font-semibold mb-3">Interpretação de Imagens e Tabelas</h2>
  <p class="mb-4">
    A IA tem dificuldade com imagens e tabelas complexas, o que pode prejudicar a extração de informações precisas. É sempre importante revisar a complexidade e o nível da tabela para aplicar uma formatação em pipes.
  </p>

  <h2 class="text-xl font-semibold mb-3">Cálculos Avançados</h2>
  <p class="mb-4">
    Embora o sistema possa realizar cálculos simples, cálculos mais complexos, com muitas casas decimais ou envolvendo várias variáveis podem não ser processados corretamente. A IA é treinada em grandes volumes de texto, não em dados matemáticos específicos ou algoritmos de cálculo.
  </p>
  <p class="mb-4">
    A IA foi projetada para gerar e interpretar linguagem, não para resolver problemas matemáticos de alta precisão. Ela “adivinha” a resposta com base em como já viu cálculos semelhantes no texto de treinamento.
  </p>

  <h2 class="text-xl font-semibold mb-3">Oscilações da OpenAI</h2>
  <p class="mb-4">
    Pode ocorrer que a IA repita mensagens ou não compreenda completamente o contexto, levando a respostas erradas ou incompletas.
  </p>

  <h2 class="text-xl font-semibold mb-3">Confusão de Dados no JSON</h2>
  <p class="mb-4">
    A IA pode se confundir com dados em JSON, especialmente quando há nomes próximos, valores semelhantes ou uma correlação errada de informações. Exemplo: se a IA precisar retornar informações baseadas no valor do campo "PATIO", ela pode apresentar erros se as relações não estiverem bem definidas no JSON.
  </p>

  <h2 class="text-xl font-semibold mb-3">Geração de Alucinações</h2>
  <p class="mb-4">
    A IA pode gerar respostas incorretas, ou "alucinações", especialmente quando o conteúdo solicitado não está claramente definido ou documentado em sua base de conhecimento.
  </p>

  <h2 class="text-xl hidden font-semibold mb-3">Diferença entre Modelos: 4.0 e Mini</h2>
  <p class="mb-4 hidden">
    O modelo 4.0 tem maior precisão na interpretação das respostas, mas o Mini é mais rápido e leve. A escolha do modelo deve ser feita com base na necessidade de exatidão ou velocidade da resposta.
  </p>

  <h2 class="text-xl font-semibold mb-3">Fluxo de Respostas Generativas</h2>
  <p class="mb-4">
    A I.A é generativa e não segue um fluxo fixo de mensagens. Embora ela siga o padrão estabelecido no prompt, as respostas podem variar dependendo do contexto da conversa. Isso significa que não é possível exigir respostas 100% padronizadas, e o chatbot pode gerar respostas ligeiramente diferentes para o mesmo tipo de pergunta.
  </p>
          </main>
        </div>

      </div>
    </div>
  </div>
</template>
    
    
      
      <script>
      import Menu from './MenuFaqIA.vue'; // Importando o menu
      export default {
        name: 'ChatbotIA-BaseConhecimento',
        components: {
            Menu
        },
        data() {
        return {
          // Lista de perguntas e respostas, o primeiro item já vem com `active: true`
          faqs: [
            { question: "O que é Duotalk?", answer: "Duotalk é uma plataforma que oferece soluções de atendimento automatizado.", active: true },
            { question: "Como configurar a inteligência artificial?", answer: "Você pode configurar a inteligência artificial seguindo as instruções no nosso guia.", active: false },
            { question: "Quais são os planos disponíveis?", answer: "Os planos variam de acordo com o número de usuários e as funcionalidades necessárias.", active: false },
          ]
        };
      },
      methods: {
        // Alterna a visibilidade da resposta
        toggleFaq(index) {
          this.faqs[index].active = !this.faqs[index].active;
        }
      }
    };
    
    </script>
    
    
    <style scoped>

    
    .faq-item h3 {
      cursor: pointer;
      padding: 10px;
      background-color: #f2f2f2;
      border-radius: 5px;
      margin: 5px 0;
      transition: background-color 0.3s ease;
    }
    
    .faq-item h3:hover {
      background-color: #e0e0e0;
    }
    
    .faq-item h3.active {
      background-color: #d0d0d0;
    }
    
    .faq-item p {
      margin: 10px 0;
      padding: 10px;
      background-color: #fafafa;
      border-left: 5px solid #cccccc;
    }
    </style>
   